jQuery(document).ready(function($){

    $('.home-slider').slick({
        dots: false,
        speed: 1500,
        infinite: true,
        autoplay: true,
        cssEase: 'ease-out',
        arrows: true,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    arrows: false,
                    dots: true,
                }
            },
        ],
    });

    $('.sfn-cart-addons.products').slick({
        dots: false,
        speed: 1500,
        infinite: true,
        autoplay: true,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });

    $('.wysiwyg .woocommerce.columns-3.product-archive .products').slick({
        dots: false,
        speed: 1500,
        infinite: true,
        autoplay: true,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });

    $('.wysiwyg .woocommerce.columns-3.product-archive .products').on('setPosition', function () {
        var slickTrackHeight = 0;
        $(this).find('.slick-slide').height('auto');
        $(this).find('.slick-slide').each(function() {
            slickTrackHeight = Math.max(slickTrackHeight, $(this).height());
        });
        var slickTrack = $(this).find('.slick-track');
        $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                }
            }
        ],
    });


});
